import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { ApplicationState } from "./types"
import { getEnvironment } from "../../utils/environment"

const initialState: ApplicationState = {
  environment: getEnvironment(undefined),
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
})

export const selectEnvironmentName = (state: RootState) =>
  state.application.environment.name
export const selectEnvironmentColour = (state: RootState) =>
  state.application.environment.colour

export default applicationSlice.reducer
