import { SetStateAction, useEffect } from "react"

import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { User } from "../../../services/elmApiTypes"
import { useDeleteUserMutation } from "../../../services/elmApi"

interface Props {
  open: boolean
  user: User | undefined
  setOpen: React.Dispatch<SetStateAction<boolean>>
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

export default function DeleteUserConfirmationDialog({
  open,
  setOpen,
  user,
}: Props) {
  const [deleteUser, { isLoading, isError, isSuccess }] =
    useDeleteUserMutation()

  const handleClose = () => {
    setOpen(false)
  }

  // Close modal if mutation promise succeeded
  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
  }, [isSuccess, handleClose])

  const handleDelete = () => {
    if (user) {
      deleteUser({ external_uuid: user.external_uuid })
    }
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        {`Delete user ${user?.email}?`}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          This will permanently delete the Cognito user account but soft delete
          the app user.
        </Typography>
        {isError ? (
          <Alert severity="error">
            Error deleting user, please try again or contact an administrator.
          </Alert>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDelete}
          disabled={isLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
