import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { OrganisationState } from "./types"

const initialState: OrganisationState = {
  name: "Elm Houseplants Limited",
  displayName: "Elm Houseplants",
}

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {},
})

export const selectName = (state: RootState) => state.organisation.name
export const selectDisplayName = (state: RootState) =>
  state.organisation.displayName

export default organisationSlice.reducer
