import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { login, signup } from "./authThunks"
import {
  selectLoginErrorMessage,
  selectLoginStatus,
  selectLoginChallenge,
  selectIsSigningUp,
  setIsSigningUp,
  selectSignUpStatus,
} from "./authSlice"
import { LoginCredentials } from "./types"
import { Formik } from "formik"

import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"

export function Login() {
  const dispatch = useAppDispatch()
  const loginErrorMessage = useAppSelector(selectLoginErrorMessage)
  const loginStatus = useAppSelector(selectLoginStatus)
  const signUpStatus = useAppSelector(selectSignUpStatus)
  const loginChallenge = useAppSelector(selectLoginChallenge)
  const isSigningUp = useAppSelector(selectIsSigningUp)

  const initialValues: LoginCredentials = {
    username: "",
    password: "",
    challenge: loginChallenge,
    newPassword: "",
  }

  const toggleSignUp = () => {
    dispatch(setIsSigningUp(!isSigningUp))
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Elm Houseplants
        </Typography>
        <Box component="div" sx={{ mt: 1 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: LoginCredentials) => {
              isSigningUp ? dispatch(signup(values)) : dispatch(login(values))
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  type={
                    loginChallenge === "NEW_PASSWORD_REQUIRED"
                      ? "hidden"
                      : "email"
                  }
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  autoComplete="email"
                  autoFocus
                  disabled={
                    loginStatus === "loading" || signUpStatus === "loading"
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={
                    loginChallenge === "NEW_PASSWORD_REQUIRED"
                      ? "hidden"
                      : "password"
                  }
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  autoComplete="current-password"
                  disabled={
                    loginStatus === "loading" || signUpStatus === "loading"
                  }
                />
                {
                  // Show new password input if challenged at login by Cognito to change password
                  loginChallenge === "NEW_PASSWORD_REQUIRED" ? (
                    <input
                      aria-label="New Password"
                      type="password"
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      required={true}
                      disabled={
                        loginStatus === "loading" || signUpStatus === "loading"
                      }
                    />
                  ) : (
                    ""
                  )
                }
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    loginStatus === "loading" || signUpStatus === "loading"
                  }
                >
                  {isSigningUp ? "Sign up" : "Log in"}
                </Button>
              </form>
            )}
          </Formik>

          <Grid container>
            <Grid item xs>
              <Link component="button" variant="body2" onClick={toggleSignUp}>
                {isSigningUp ? "Log in" : "Sign up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <span>{loginChallenge}</span>

      <div>
        {loginStatus === "failed" ? (
          <p>{loginErrorMessage || "An unknown error occurred"}</p>
        ) : (
          ""
        )}
      </div>
    </Container>
  )
}
