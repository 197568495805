import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"
import { Users } from "../screens/users"
import { PlantKinds } from "../screens/plantKinds"
import { PlantInstances } from "../screens/plantInstances"
import { Dashboard } from "../screens/Dashboard"
import { Layout } from "../components/layout"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Dashboard />} />
      <Route path="users" element={<Users />} />
      <Route path="plant/kind" element={<PlantKinds />} />
      <Route path="plant/instance" element={<PlantInstances />} />
    </Route>,
  ),
)
