/**
 * Calculate Cognito configs from frontend URL
 *
 * @param hostname
 * @returns backend URL
 */

export interface CognitoConfig {
  userPoolId: string
  userPoolWebClientId: string
}

export function getCognitoConfig(hostname: string | undefined): CognitoConfig {
  if (hostname === undefined) {
    hostname = window.location.hostname
  }

  // Default to dev for local
  let config: CognitoConfig = {
    userPoolId: "eu-west-2_BDqxnIFeR",
    userPoolWebClientId: "6jm4fmg2rjign9ounv5jc6ljs4",
  }

  if (hostname.endsWith("app.elmplants.co.uk")) {
    let hostnameParts = hostname.split(".")

    // Test environment
    if (hostnameParts[1] === "test") {
      config.userPoolId = "eu-west-2_6QtIRHr0g"
      config.userPoolWebClientId = "4hf91aiemrpc8a4rutq99ofuhe"
    }
    // Prod (no environment subdomain)
    if (hostnameParts[1] === "app") {
      config.userPoolId = "eu-west-2_tKG2nAdyb"
      config.userPoolWebClientId = "4ka6va9fbb8amcsgn09n1th915"
    }
  }

  return config
}
