import React from "react"
import {
  Grid,
  Avatar,
  Box,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Typography,
  IconButtonProps,
  CardActions,
  Collapse,
  styled,
  Button,
  Divider,
} from "@mui/material"

import { blue } from "@mui/material/colors"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MobileStepper from "@mui/material/MobileStepper"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { useTheme } from "@mui/material/styles"
import { PlantKind } from "../services/elmApiTypes"
import { Edit } from "@mui/icons-material"
import { useAppDispatch } from "../app/hooks"
import {
  setPlantKindToEdit,
  setShowAddEditModal,
} from "../features/plantKind/plantKindSlice"

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}
type CardPlantCarouselProps = {
  record: PlantKind
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function CardPlantCarousel({ record }: CardPlantCarouselProps) {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const [expanded, setExpanded] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = 6
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleEdit = () => {
    dispatch(setPlantKindToEdit(record))
    dispatch(setShowAddEditModal(true))
  }

  const images = [
    record.main_image,
    record.image_1,
    record.image_2,
    record.image_3,
    record.image_4,
    record.image_5,
  ]
  return (
    <>
      <Grid item xs={4} key={record.id}>
        <Card sx={{ borderRadius: 4 }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: blue[600] }} aria-label="plants">
                {record.id}
              </Avatar>
            }
            action={
              <IconButton onClick={handleEdit}>
                <Edit />
              </IconButton>
            }
            title={record.common_name}
            subheader={record.scientific_name}
          />
          <Box sx={{ maxWidth: 400, flexGrow: 1 }} key={record.id}>
            <Box
              component="img"
              sx={{
                height: 255,
                display: "block",
                maxWidth: 400,
                overflow: "hidden",
                width: "100%",
              }}
              src={images[activeStep]}
              alt="images"
            />
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
          <Divider />
          <CardContent>
            <h4 style={{ color: blue[600], marginTop: 1 }}>Description:</h4>
            <Typography variant="body1" style={{ flexWrap: "wrap" }}>
              {record.plant_description}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent>
              <h4 style={{ color: blue[600] }}>Type:</h4>
              <div
                style={{
                  display: "grid",
                }}
              >
                <Typography paragraph>
                  Soil type: {record.is_soil_type_wet ? "Wet" : "Dry"}
                </Typography>
              </div>
              <Divider />
              <h4 style={{ color: blue[600] }}>Aliases:</h4>
              <div
                style={{
                  display: "grid",
                }}
              >
                <Typography paragraph>Alias 1: {record.alias_1}</Typography>
                <Typography paragraph>Alias 2: {record.alias_2}</Typography>
                <Typography paragraph>Alias 3: {record.alias_3}</Typography>
                <Typography paragraph>Alias 4: {record.alias_4}</Typography>
              </div>
              <Divider />
              <h4 style={{ color: blue[600] }}>Light:</h4>
              <div
                style={{
                  display: "grid",
                }}
              >
                <Typography paragraph>
                  Light Tolerance (min): {record.light_tolerance_min}
                </Typography>
                <Typography paragraph>
                  Light Tolerance (max): {record.light_tolerance_max}
                </Typography>
              </div>
              <Divider />
              <h4 style={{ color: blue[600] }}>Water Frequency:</h4>
              <div
                style={{
                  display: "grid",
                }}
              >
                <Typography paragraph>
                  Spring: {record.watering_frequency_days_spring}
                </Typography>
                <Typography paragraph>
                  Summer: {record.watering_frequency_days_summer}
                </Typography>
                <Typography paragraph>
                  Autumn: {record.watering_frequency_days_autumn}
                </Typography>
                <Typography paragraph>
                  Winter: {record.watering_frequency_days_winter}
                </Typography>
              </div>
              <Divider />
              <h4 style={{ color: blue[600] }}>Feeding Frequency:</h4>
              <div
                style={{
                  display: "grid",
                }}
              >
                <Typography paragraph>
                  Spring: {record.feeding_frequency_days_spring}
                </Typography>
                <Typography paragraph>
                  Summer:
                  {record.feeding_frequency_days_summer}
                </Typography>

                <Typography paragraph>
                  Autumn:
                  {record.feeding_frequency_days_autumn}
                </Typography>
                <Typography paragraph>
                  Winter:
                  {record.feeding_frequency_days_winter}
                </Typography>
              </div>
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    </>
  )
}
