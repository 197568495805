import { useEffect } from "react"
import { Home } from "./Home"
import { Loading } from "./Loading"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectIdToken } from "./authSlice"
import { getIdToken } from "./authThunks"

export function Token() {
  const idToken = useAppSelector(selectIdToken)
  // Check if user has a token and fetch if not.
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!idToken) {
      dispatch(getIdToken())
    }
  }, [idToken, dispatch])

  return idToken ? <Home /> : <Loading />
}
