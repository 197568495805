// Themeprovider should be imported before any components that use themes
// See https://github.com/mui/material-ui/issues/31835#issuecomment-1519178057
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { Session } from "./features/auth/Session"

import { useAppSelector } from "./app/hooks"
import { selectPaletteMode } from "./features/theme/themeSlice"

function App() {
  // Get redux application state
  const paletteMode = useAppSelector(selectPaletteMode)

  const theme = createTheme({
    palette: {
      mode: paletteMode,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Session />
    </ThemeProvider>
  )
}

export default App
