import { Token } from "./Token"
import { Login } from "./Login"
import { useAppSelector } from "../../app/hooks"
import { selectAuth } from "./authSlice"

export function Auth() {
  const isAuthenticated = useAppSelector(selectAuth)

  return isAuthenticated ? <Token /> : <Login />
}
