import { useGetPlantKindsQuery } from "../services/elmApi"
import { AddEditPlantKind } from "./addEditPlantKind"
import { Grid, Box, CircularProgress } from "@mui/material"
import CardPlantCarousel from "./cardPlantCarousel"

export function PlantKinds() {
  // Using a query hook automatically fetches data and returns query values
  const { data, error, isLoading } = useGetPlantKindsQuery()

  return (
    <>
      <h1>Plants Page</h1>
      <AddEditPlantKind />

      {error ? (
        <>Error loading records</>
      ) : isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} sx={{ marginTop: "100px" }} />
        </Box>
      ) : data ? (
        <Box sx={{ flexGrow: 1, marginBottom: 12 }}>
          <h4>Total Number of Plants: {data.length}</h4>
          <Grid container wrap="wrap" spacing={4}>
            {data.map((record) => {
              return <CardPlantCarousel key={record.id} record={record} />
            })}
          </Grid>
        </Box>
      ) : null}
    </>
  )
}
