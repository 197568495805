// Local state for drawer opening and closing
import { useState } from "react"
// Redux state for application variables
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectDisplayName } from "../features/organisation/organisationSlice"
import {
  selectEnvironmentName,
  selectEnvironmentColour,
} from "../features/application/applicationSlice"
import {
  selectPaletteMode,
  togglePaletteMode,
} from "../features/theme/themeSlice"
// Layout resources for drawer and menu
import { Drawer } from "./drawer"
import { AppBar } from "./appBar"
import { MenuItems } from "./menuItems"
// Material UI components
import { Box, CssBaseline, Container, Chip, Stack } from "@mui/material"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Typography from "@mui/material/Typography"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"

import { Outlet } from "react-router-dom"

export function Layout() {
  // Set local drawer state
  const [open, setOpen] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  // Get redux application state
  const displayName = useAppSelector(selectDisplayName)
  const paletteMode = useAppSelector(selectPaletteMode)
  const environmentName = useAppSelector(selectEnvironmentName)
  const environmentColour = useAppSelector(selectEnvironmentColour)

  // Set redux application state
  const dispatch = useAppDispatch()

  // Handle theme palette mode change from dark mode to light mode and back
  const handlePaletteModeChange = () => {
    dispatch(togglePaletteMode())
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {displayName}
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center">
            <Chip label={environmentName} color={environmentColour} />
            <IconButton onClick={handlePaletteModeChange} color="inherit">
              {paletteMode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <MenuItems />
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container>
          <Outlet />
        </Container>
      </Box>
    </Box>
  )
}
