import { useAddUserMutation } from "../services/elmApi"
import { Formik } from "formik"
import { AddUserRequest } from "../services/elmApiTypes"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import { TextField } from "@mui/material"

export function AddUser() {
  const [addUser, { isLoading, isError }] = useAddUserMutation()

  const initialValues: AddUserRequest = {
    email: "",
    external_uuid: "",
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: AddUserRequest) => {
          addUser(values)
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              type="email"
              required
              id="email"
              label="Email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              type="text"
              required
              id="external_uuid"
              label="External UUID"
              name="external_uuid"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.external_uuid}
              disabled={isLoading}
              sx={{ marginLeft: 3 }}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{ marginTop: 3, marginLeft: 3, borderRadius: 3 }}
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
      {isError ? <Alert severity="error">Error adding user.</Alert> : <></>}
    </div>
  )
}
