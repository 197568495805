import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { PlantKindState } from "./types"
import { PlantKind } from "../../services/elmApiTypes"

const initialState: PlantKindState = {
  plantKindToEdit: undefined,
  showAddEditModal: false,
}

export const plantKindSlice = createSlice({
  name: "plantKind",
  initialState,
  reducers: {
    setPlantKindToEdit: (
      state,
      action: PayloadAction<PlantKind | undefined>,
    ) => {
      state.plantKindToEdit = action.payload
    },
    setShowAddEditModal: (state, action: PayloadAction<boolean>) => {
      state.showAddEditModal = action.payload
    },
  },
})

export const { setPlantKindToEdit, setShowAddEditModal } =
  plantKindSlice.actions

export const selectPlantKindToEdit = (state: RootState) =>
  state.plantKind.plantKindToEdit
export const selectShowAddEditModal = (state: RootState) =>
  state.plantKind.showAddEditModal

export default plantKindSlice.reducer
