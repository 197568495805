import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { CircularProgress } from "@mui/material"

export function Loading() {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 30,
        }}
      >
        <CircularProgress size={80} />
      </Box>
    </Container>
  )
}
