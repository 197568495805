import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import { Link } from "react-router-dom"
import {
  HomeRounded,
  LocalFloristRounded,
  PeopleRounded,
  QrCodeTwoTone,
} from "@mui/icons-material"

export function MenuItems() {
  return (
    <List component="nav">
      <Divider sx={{ my: 1 }} />
      {/* Users */}
      <ListItemButton component={Link} to="/">
        <ListItemIcon>
          <HomeRounded />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton component={Link} to="/plant/instance">
        <ListItemIcon>
          <QrCodeTwoTone />
        </ListItemIcon>
        <ListItemText primary="QR Codes" />
      </ListItemButton>
      <ListItemButton component={Link} to="/plant/kind">
        <ListItemIcon>
          <LocalFloristRounded />
        </ListItemIcon>
        <ListItemText primary="Plants" />
      </ListItemButton>
      <ListItemButton component={Link} to="/users">
        <ListItemIcon>
          <PeopleRounded />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </List>
  )
}
