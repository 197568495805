import { useState } from "react"

import { useGetUsersQuery } from "../services/elmApi"

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import DeleteUserConfirmationDialog from "./users/components/deleteUserConfirmationDialog"
import { User } from "../services/elmApiTypes"
import { AddUser } from "./addUser"

export function Users() {
  const { data, error, isLoading } = useGetUsersQuery()

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const [userToDelete, setUserToDelete] = useState<User | undefined>(undefined)

  const clickDelete = (user: User | undefined) => {
    setDeleteOpen(true)
    setUserToDelete(user)
  }

  return (
    <>
      <DeleteUserConfirmationDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        user={userToDelete}
      />
      <h1>Users</h1>
      <AddUser />
      {error ? (
        <>Error loading records</>
      ) : isLoading ? (
        <>Loading...</>
      ) : data ? (
        <Box mb={12} sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            {data.map((record) => {
              return (
                <Grid item xs={4} key={record.id}>
                  <Card style={{ borderRadius: 12 }}>
                    <CardContent>
                      <Typography sx={{ mb: 3 }} variant="h6" component="div">
                        {record.email}
                      </Typography>
                      <div style={{ display: "grid" }}>
                        <Typography mb={1.5} variant="body2">
                          ID: {record.id}
                        </Typography>
                        <Typography mb={1.5} variant="body2">
                          Date: {record.created_at}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                        >
                          {record.external_uuid}
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button color="error" onClick={() => clickDelete(record)}>
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      ) : null}
    </>
  )
}
