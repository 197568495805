import { Environment } from "../features/application/types"

/**
 * Calculate environment name and colour based on hostname
 *
 * @param hostname
 * @returns environment config
 */
export function getEnvironment(hostname: string | undefined): Environment {
  if (hostname === undefined) {
    hostname = window.location.hostname
  }

  // Default to local config
  let environment: Environment = {
    name: "Local",
    colour: "info",
  }

  if (hostname.endsWith("app.elmplants.co.uk")) {
    let hostnameParts = hostname.split(".")

    // Dev environment
    if (hostnameParts[1] === "dev") {
      environment.name = "Dev"
      environment.colour = "success"
    }
    // Test environment
    if (hostnameParts[1] === "test") {
      environment.name = "Test"
      environment.colour = "warning"
    }
    // Prod (no environment subdomain)
    if (hostnameParts[1] === "app") {
      environment.name = "Prod"
      environment.colour = "error"
    }
  }

  return environment
}
