import { Auth } from "./Auth"
import { Loading } from "./Loading"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCheckedCurrentSessionValid } from "./authSlice"
import { isCurrentSessionValid } from "./authThunks"

export function Session() {
  const checkedCurrentSessionValid = useAppSelector(
    selectCheckedCurrentSessionValid,
  )
  // Check if user has an active session before showing login screen.
  // Needed if user reloads the page but is already logged in, to
  // prevent forcing the user to log in again.
  const dispatch = useAppDispatch()
  if (!checkedCurrentSessionValid) {
    dispatch(isCurrentSessionValid())
  }

  return checkedCurrentSessionValid ? <Auth /> : <Loading />
}
