import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { ThemeState } from "./types"

const initialState: ThemeState = {
  paletteMode: "light",
}

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    togglePaletteMode: (state) => {
      state.paletteMode = state.paletteMode === "dark" ? "light" : "dark"
    },
  },
})

export const { togglePaletteMode } = themeSlice.actions

export const selectPaletteMode = (state: RootState) => state.theme.paletteMode

export default themeSlice.reducer
