import { useGetPlantInstancesQuery } from "../services/elmApi"
import QRCode from "react-qr-code"
import { AddPlantInstance } from "./addPlantInstance"
import Box from "@mui/material/Box"
import { useAppSelector } from "../app/hooks"
import { selectPaletteMode } from "../features/theme/themeSlice"
import { useEffect, useRef, useState } from "react"
import Modal from "@mui/material/Modal"
import qrCodeLogo from "../../qrLogo/Elm_QR_Code_Print_v1_no_borders.jpg"
import CircularProgress from "@mui/material/CircularProgress"
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  DataGrid,
  GridCellParams,
  GridCsvExportMenuItem,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
} from "@mui/x-data-grid"
import moment from "moment"
import { Divider, Grid, IconButton, Tooltip } from "@mui/material"
import { getBackendUrl } from "../utils/backendUrl"
import { selectIdToken } from "../features/auth/authSlice"

export function PlantInstances() {
  // Using a query hook automatically fetches data and returns query values
  const { data, error, isLoading } = useGetPlantInstancesQuery(undefined, {
    pollingInterval: 5000,
  })
  const svgRef = useRef<SVGSVGElement | null>(null)
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const [filteredData] = useState<typeof data | null>(null)
  const [isQrCodeModalOpen, setQrCodeModalOpen] = useState(false)
  const paletteMode = useAppSelector(selectPaletteMode)
  const [selectedQrCode, setSelectedQrCode] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const baseUrl =
    process.env.BACKEND_URL || getBackendUrl(process.env.FRONTEND_URL)

  const token = useAppSelector(selectIdToken)

  // Handles the click on the modal for the qr code in the data-grid
  const handleQrCodeClick = (params: GridCellParams) => {
    const qrCodeValue = params.row.url as string
    setSelectedQrCode(qrCodeValue)
    setQrCodeModalOpen(true)
  }

  const handleCloseQrCodeModal = () => {
    setQrCodeModalOpen(false)
  }

  // handleSelectionModelChange affects how the deselection and selected rows work
  const handleSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    // This finds the deselected row
    const deselectedRows = selectedRows.filter(
      (rowId) => !newSelection.includes(rowId),
    )

    // this will find the row that has just been created
    const newlySelectedRows = newSelection.filter(
      (rowId) => !selectedRows.includes(rowId),
    )

    // this updates the rows state
    setSelectedRows((prevSelectedRows) => [
      ...prevSelectedRows.filter((rowId) => !deselectedRows.includes(rowId)),
      ...newlySelectedRows,
    ])
  }

  useEffect(() => {
    const newSelectedRows =
      data
        ?.filter(
          (record) => moment().diff(moment(record.created_at), "seconds") < 5,
        )
        .map((record) => record.id) || []

    setSelectedRows((prevSelectedRows) => [
      ...prevSelectedRows,
      ...newSelectedRows,
    ])
  }, [data])

  // downloadPdf allows the user to download a pdf from the backend based on the base url of where the application/pdf is stored
  const downloadPdf = async () => {
    setLoading(true)
    const params = selectedRows.join("&plant_instance_id=")

    fetch(`${baseUrl}/admin/plant_instance?plant_instance_id=${params}`, {
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var _url = window.URL.createObjectURL(blob)
        window.open(_url, "_blank")?.focus()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })

    // fs.create

    // getPdf().unwrap().then((payload) => {
    //   if (typeof payload === 'string') {
    //     const file = new Blob([payload], {type: 'application/pdf'});
    //     window.open(URL.createObjectURL(file), '_blank')?.focus();
    //   }
    //   console.log(typeof payload)
    //   console.log(payload)
    // }).catch((error) => {
    //   console.error(error)}
    // )

    // getPdf().then((data) => {
    //   console.log(data)
    //   const file = new Blob([data], {type: 'application/pdf'});
    //   window.open(URL.createObjectURL(file), '_blank')?.focus();
    //   // console.log(data.blob())
    // }, (error) => {
    //   console.warn(error)
    // })
    // .then((data) => {
    // })
  }

  // const handleSearch = (searchText: string) => {
  //   const lowerCaseSearchText = searchText.toLowerCase()
  //   const filteredResults =
  //     data?.filter((record) =>
  //       record.plant_kind.common_name
  //         .toLowerCase()
  //         .includes(lowerCaseSearchText),
  //     ) || null
  //   setFilteredData(filteredResults)
  // }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    )
  }

  return (
    <div>
      <h1>QR Code Page</h1>
      <Divider />
      <Box>
        <h3>Generate QR Codes</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <AddPlantInstance />
          {/* <div style={{ marginLeft: 150, marginTop: "-30px" }}>
            <Input
              type="text"
              size="small"
              placeholder="Search for a Plant"
              sx={{ maxWidth: 200 }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
          <LoadingButton
            variant="contained"
            type="submit"
            size="small"
            sx={{
              borderRadius: 50,
              marginLeft: 2,
            }}
            onClick={downloadPdf}
            loading={loading}
          >
            Download
          </LoadingButton>
        </div>
      </Box>
      {error ? (
        <>Error loading records</>
      ) : isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} sx={{ marginTop: "100px" }} />
        </Box>
      ) : data ? (
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/*<Typography variant="subtitle2" sx={{ marginRight: 3 }}>
                Total Number of QR Codes: {data.length}
              </Typography>
               ^^^^^ code above was used to search for total number of qr codes, maybe it can be used in future once the api end has been adjusted for it */}
          </Box>
          <Grid container spacing={4} mt={1} ml={0.1} sx={{ width: "110%" }}>
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 20, page: 0 },
                },
              }}
              localeText={{
                toolbarDensity: "Adjust Row Density",
              }}
              pageSizeOptions={[10, 15, 20, 25, 50, 75, 100]}
              slots={{
                toolbar: CustomToolbar,
              }}
              checkboxSelection
              density="comfortable"
              rowHeight={78}
              disableRowSelectionOnClick
              sx={{
                width: "110%",
                marginTop: "20px",
                right: "4%",
                borderRadius: "10px",
                padding: "50px",
                backgroundColor: paletteMode === "dark" ? "#121212" : "white",
                color: paletteMode === "dark" ? "white" : "black",
                // alignItems: "center",
              }}
              rows={(filteredData || data || []).map((record) => ({
                id: record.id,
                uid: record.uid,
                createdAt: record.created_at,
                scientificName: record.plant_kind.scientific_name,
                commonName: record.plant_kind.common_name,
                url: record.url,
                is_claimed: record.plant_claims.length > 0,
              }))}
              columns={[
                {
                  field: "id",
                  headerName: "ID:",
                  width: 70,
                },
                {
                  field: "uid",
                  headerName: "UID:",
                  width: 100,
                },
                {
                  field: "url",
                  headerName: "QR Code:",
                  width: 150,
                  renderCell: (params: GridCellParams) => (
                    <div
                      ref={svgRef as React.LegacyRef<HTMLDivElement>}
                      onClick={() => handleQrCodeClick(params)}
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        height: "88px", // Overall style of the qrcode data-grid cell
                        width: "70px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%", //elm logo style here <<<<<
                          height: "100%",
                          backgroundImage: `url(${qrCodeLogo})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                      <QRCode
                        level="M"
                        value={params.row.url}
                        size={44} // style of the qr code itself
                        bgColor="#f9f2ed" //this hex changes the white within the qr code to the elm creamy colour within the data grid cell
                        fgColor="#2b1438" //this hex colour change, changes the qr code black bar colour to the purple colour within the data grid cell
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </div>
                  ),
                },
                { field: "commonName", headerName: "Plant Name:", width: 200 },
                {
                  field: "scientificName",
                  headerName: "Scientific Plant Name:",
                  width: 260,
                },
                {
                  field: "createdAt",
                  headerName: "Created At:",
                  width: 190,
                  valueFormatter: (params) => {
                    const formattedDateTime = moment(params.value).format(
                      "DD/MM/YYYY | HH:mm:ss A",
                    )
                    return formattedDateTime
                  },
                },
                {
                  field: "is_claimed",
                  headerName: "Claimed?",
                  headerAlign: "center",
                  width: 100,
                  align: "center",
                  renderCell: (params: GridCellParams) => (
                    <Tooltip
                      title={
                        params.row.is_claimed
                          ? `Claimed on ${moment(
                              params.row.createdAt,
                            ).toLocaleString()}`
                          : "Not claimed"
                      }
                    >
                      <IconButton>
                        <Brightness1RoundedIcon
                          sx={{
                            color: params.row.is_claimed ? "red" : "green",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ),
                },
              ]}
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={handleSelectionModelChange}
            />
          </Grid>
        </Box>
      ) : null}
      <Modal open={isQrCodeModalOpen} onClose={handleCloseQrCodeModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%", // the styles act the same way as the qr code data grid cell
            maxWidth: "500px",
            height: "80%",
            maxHeight: "600px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              height: "110%",
              backgroundImage: `url(${qrCodeLogo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          {selectedQrCode && (
            <QRCode
              level="M"
              value={selectedQrCode}
              size={500}
              bgColor="#f9f2ed" //this hex changes the white within the qr code to the elm creamy colour
              fgColor="#2b1438" //this hex colour change, changes the qr code black bar colour to the purple colour
              style={{
                position: "absolute", //qr code styles within the modal
                top: "65%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                height: "50%",
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}
