import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AuthState } from "./types"
import {
  isCurrentSessionValid,
  login,
  logout,
  signup,
  getIdToken,
} from "./authThunks"

const initialState: AuthState = {
  isSigningUp: false,
  signUpStatus: "idle",
  isAuthenticated: false,
  idToken: undefined,
  getIdTokenStatus: "idle",
  checkedCurrentSessionValid: false,
  isCurrentSessionValidStatus: "idle",
  loginStatus: "idle",
  loginChallenge: undefined,
  loginErrorCode: undefined,
  loginErrorMessage: undefined,
  logoutStatus: "idle",
  username: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsSigningUp: (state, action: PayloadAction<boolean>) => {
      state.isSigningUp = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // IsLoggedIn async thunk reducers
    builder
      .addCase(isCurrentSessionValid.pending, (state) => {
        state.isCurrentSessionValidStatus = "loading"
      })
      .addCase(isCurrentSessionValid.fulfilled, (state, action) => {
        state.isCurrentSessionValidStatus = "idle"
        state.checkedCurrentSessionValid = true
        state.isAuthenticated = action.payload
      })
      .addCase(isCurrentSessionValid.rejected, (state, action) => {
        state.isCurrentSessionValidStatus = "failed"
        state.checkedCurrentSessionValid = true
        state.isAuthenticated = false
      })
    // Signup async thunk reducers
    builder
      .addCase(signup.pending, (state) => {
        state.signUpStatus = "loading"
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.signUpStatus = "idle"
        state.isAuthenticated = action.payload?.authenticated || false
        state.username = action.payload?.username
        state.loginChallenge = action.payload?.challenge
      })
      .addCase(signup.rejected, (state, action) => {
        state.signUpStatus = "failed"
        state.loginErrorCode = action.error.code
        state.loginErrorMessage = action.error.message
      })
    // Login async thunk reducers
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = "loading"
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginStatus = "idle"
        state.isAuthenticated = action.payload?.authenticated || false
        state.username = action.payload?.username
        state.loginChallenge = action.payload?.challenge
      })
      .addCase(login.rejected, (state, action) => {
        state.loginStatus = "failed"
        state.loginErrorCode = action.error.code
        state.loginErrorMessage = action.error.message
      })
    // Get Access Token async thunk reducers
    builder
      .addCase(getIdToken.pending, (state) => {
        state.getIdTokenStatus = "loading"
      })
      .addCase(getIdToken.fulfilled, (state, action) => {
        state.getIdTokenStatus = "idle"
        state.idToken = action.payload
      })
      .addCase(getIdToken.rejected, (state) => {
        state.getIdTokenStatus = "failed"
        state.idToken = undefined
      })
    // Logout async thunk reducers
    builder
      .addCase(logout.pending, (state) => {
        state.logoutStatus = "loading"
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.logoutStatus = "idle"
        state.isAuthenticated = false
      })
      .addCase(logout.rejected, (state) => {
        state.logoutStatus = "failed"
      })
  },
})

export const { setIsSigningUp } = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
export const selectAuth = (state: RootState) => state.auth.isAuthenticated
export const selectCheckedCurrentSessionValid = (state: RootState) =>
  state.auth.checkedCurrentSessionValid
export const selectLoginChallenge = (state: RootState) =>
  state.auth.loginChallenge
export const selectLoginStatus = (state: RootState) => state.auth.loginStatus
export const selectLoginErrorMessage = (state: RootState) =>
  state.auth.loginErrorMessage
export const selectIdToken = (state: RootState) => state.auth.idToken
export const selectIsSigningUp = (state: RootState) => state.auth.isSigningUp
export const selectSignUpStatus = (state: RootState) => state.auth.signUpStatus

export default authSlice.reducer
