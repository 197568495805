import {
  useAddPlantKindMutation,
  useEditPlantKindMutation,
} from "../services/elmApi"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { Formik } from "formik"
import { AddEditPlantKindRequest } from "../services/elmApiTypes"
import Button from "@mui/material/Button"
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import {
  selectPlantKindToEdit,
  selectShowAddEditModal,
  setPlantKindToEdit,
  setShowAddEditModal,
} from "../features/plantKind/plantKindSlice"

export function AddEditPlantKind() {
  const dispatch = useAppDispatch()
  const [addPlantKind, { isLoading }] = useAddPlantKindMutation()
  const [editPlantKind] = useEditPlantKindMutation()
  const plantKindToEdit = useAppSelector(selectPlantKindToEdit)
  const showAddEditModal = useAppSelector(selectShowAddEditModal)

  const handleOpen = () => {
    dispatch(setShowAddEditModal(true))
  }

  const handleClose = () => {
    dispatch(setShowAddEditModal(false))
    dispatch(setPlantKindToEdit(undefined))
  }

  const initialValues: AddEditPlantKindRequest = {
    id: plantKindToEdit?.id || undefined,
    scientific_name: plantKindToEdit?.scientific_name || "Scientific name",
    common_name: plantKindToEdit?.common_name || "Common name",
    alias_1: plantKindToEdit?.alias_1 || "Alias 1",
    alias_2: plantKindToEdit?.alias_2 || "Alias 2",
    alias_3: plantKindToEdit?.alias_3 || "Alias 3",
    alias_4: plantKindToEdit?.alias_4 || "Alias 4",
    alias_5: plantKindToEdit?.alias_5 || "Alias 5",
    plant_description: plantKindToEdit?.plant_description || "Description",
    main_image: plantKindToEdit?.main_image || "main image",
    image_1: plantKindToEdit?.image_1 || "image 1",
    image_2: plantKindToEdit?.image_2 || "image 2",
    image_3: plantKindToEdit?.image_3 || "image 3",
    image_4: plantKindToEdit?.image_4 || "image 4",
    image_5: plantKindToEdit?.image_5 || "image 5",
    light_tolerance_min: plantKindToEdit?.light_tolerance_min || 1,
    light_tolerance_max: plantKindToEdit?.light_tolerance_max || 2,
    watering_frequency_days_spring:
      plantKindToEdit?.watering_frequency_days_spring || 14,
    watering_frequency_days_summer:
      plantKindToEdit?.watering_frequency_days_summer || 7,
    watering_frequency_days_autumn:
      plantKindToEdit?.watering_frequency_days_autumn || 21,
    watering_frequency_days_winter:
      plantKindToEdit?.watering_frequency_days_winter || 28,
    feeding_frequency_days_spring:
      plantKindToEdit?.feeding_frequency_days_spring || 21,
    feeding_frequency_days_summer:
      plantKindToEdit?.feeding_frequency_days_summer || 14,
    feeding_frequency_days_autumn:
      plantKindToEdit?.feeding_frequency_days_autumn || 28,
    feeding_frequency_days_winter:
      plantKindToEdit?.feeding_frequency_days_winter || 36,
    is_soil_type_wet: plantKindToEdit?.is_soil_type_wet || false,
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Add a Plant
      </Button>
      <Modal
        open={showAddEditModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container component="main" maxWidth="xl">
          <Paper
            variant="outlined"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
              paddingTop: 15,
              paddingBottom: 25,
            }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={(values: AddEditPlantKindRequest) => {
                // If an ID exists, edit (PATCH) the record, otherwise create (POST)
                if (values.id) {
                  editPlantKind(values)
                } else {
                  addPlantKind(values)
                }
                handleClose()
              }}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={{ xs: 2 }}
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: 50,
                      paddingRight: 35,
                    }}
                  >
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        id="standard-search"
                        label="scientific name"
                        name="scientific_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.scientific_name}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        id="common_name"
                        label="common name"
                        name="common_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.common_name}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="light_tolerance_min"
                        label="light tolerance (min)"
                        name="light_tolerance_min"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.light_tolerance_min}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={2}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="light_tolerance_max"
                        label="light tolerance (max)"
                        name="light_tolerance_max"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.light_tolerance_max}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mt={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.is_soil_type_wet}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ "aria-label": "controlled" }}
                            id="is_soil_type_wet"
                            name="is_soil_type_wet"
                            disabled={isLoading}
                          />
                        }
                        label="Wet soil"
                      />
                    </Grid>
                    <Grid item xs={12} md={6.5} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        id="plant_description"
                        label="Description"
                        name="plant_description"
                        multiline
                        maxRows={2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.plant_description}
                        disabled={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        id="alias_1"
                        label="alias 1"
                        name="alias_1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alias_1}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="alias_2"
                        label="alias 2"
                        name="alias_2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alias_2}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="alias_3"
                        label="alias 3"
                        name="alias_3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alias_3}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="alias_4"
                        label="alias 4"
                        name="alias_4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alias_4}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="alias_5"
                        label="alias 5"
                        name="alias_5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alias_5}
                        disabled={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        id="main_image"
                        label="main image"
                        name="main_image"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.main_image}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        id="image_1"
                        label="image 1"
                        name="image_1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.image_1}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="image_2"
                        label="image 2"
                        name="image_2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.image_2}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="image_3"
                        label="image 3"
                        name="image_3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.image_3}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="image_4"
                        label="image 4"
                        name="image_4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.image_4}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="text"
                        id="image_5"
                        label="image 5"
                        name="image_5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.image_5}
                        disabled={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="watering_frequency_days_spring"
                        label="watering frequency days spring"
                        name="watering_frequency_days_spring"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.watering_frequency_days_spring}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="watering_frequency_days_summer"
                        label="watering frequency days summer"
                        name="watering_frequency_days_summer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.watering_frequency_days_summer}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="watering_frequency_days_autumn"
                        label="watering frequency days autumn"
                        name="watering_frequency_days_autumn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.watering_frequency_days_autumn}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="watering_frequency_days_winter"
                        label="watering frequency days winter"
                        name="watering_frequency_days_winter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.watering_frequency_days_winter}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="feeding_frequency_days_spring"
                        label="feeding frequency days spring"
                        name="feeding_frequency_days_spring"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.feeding_frequency_days_spring}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="feeding_frequency_days_summer"
                        label="feeding frequency days summer"
                        name="feeding_frequency_days_summer"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.feeding_frequency_days_summer}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="feeding_frequency_days_autumn"
                        label="feeding frequency days autumn"
                        name="feeding_frequency_days_autumn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.feeding_frequency_days_autumn}
                        disabled={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} mr={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        type="number"
                        required
                        id="feeding_frequency_days_winter"
                        label="feeding frequency days winter"
                        name="feeding_frequency_days_winter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.feeding_frequency_days_winter}
                        disabled={isLoading}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={{ xs: 2 }}
                    style={{
                      paddingLeft: 50,
                      paddingRight: 30,
                    }}
                  >
                    <Grid item xs={12} md={2} mr={4} mt={3}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Container>
      </Modal>
    </div>
  )
}
