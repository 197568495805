import {
  useAddPlantInstanceMutation,
  useGetPlantKindsQuery,
} from "../services/elmApi"
import { Formik } from "formik"
import { AddPlantInstanceRequest } from "../services/elmApiTypes"
import Button from "@mui/material/Button"
import { TextField, Select, MenuItem } from "@mui/material"
import { useAppSelector } from "../app/hooks"
import { selectPaletteMode } from "../features/theme/themeSlice"

export function AddPlantInstance() {
  const [addPlantInstance, { isLoading }] = useAddPlantInstanceMutation()

  const getPlantKinds = useGetPlantKindsQuery()
  const plantKindsData = getPlantKinds.data
  const plantKindsIsLoading = getPlantKinds.isLoading
  const paletteMode = useAppSelector(selectPaletteMode)

  const initialValues: AddPlantInstanceRequest = {
    plant_kind_id: 1,
    count: 1,
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: AddPlantInstanceRequest) => {
          addPlantInstance(values)
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Select
              id="plant_kind_id"
              name="plant_kind_id"
              label="Plant kind"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.plant_kind_id}
              disabled={plantKindsIsLoading}
              sx={{
                width: 400,
                height: 40,
                borderRadius: 50,
                backgroundColor: paletteMode === "dark" ? "#121212" : "white",
                color: paletteMode === "dark" ? "white" : "black",
              }}
            >
              {plantKindsData
                ? plantKindsData.map((record) => {
                    return (
                      <MenuItem value={record.id} key={record.id}>
                        {record.scientific_name} ({record.common_name})
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
            <TextField
              type="number"
              required
              id="count"
              label="Number of QR Codes"
              name="count"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.count}
              disabled={isLoading}
              sx={{
                width: 200,
                height: 40,
                marginLeft: 2,
                borderRadius: 50,
                backgroundColor: paletteMode === "dark" ? "#121212" : "white",
                color: paletteMode === "dark" ? "white" : "black",
              }}
              InputProps={{
                sx: { height: 40, borderRadius: 20, marginLeft: 0 },
              }}
            />
            <Button
              variant="outlined"
              type="submit"
              disabled={isLoading}
              sx={{
                borderRadius: 50,
                marginLeft: 5,
              }}
            >
              Generate
            </Button>
          </form>
        )}
      </Formik>
    </div>
  )
}
