import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import authReducer from "../features/auth/authSlice"
import applicationReducer from "../features/application/applicationSlice"
import organisationReducer from "../features/organisation/organisationSlice"
import plantKindReducer from "../features/plantKind/plantKindSlice"
import themeReducer from "../features/theme/themeSlice"
import { elmApi } from "../services/elmApi"
import { PaletteMode } from "@mui/material"

const initialPaletteMode = localStorage.getItem("paletteMode") || "light"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    application: applicationReducer,
    organisation: organisationReducer,
    plantKind: plantKindReducer,
    theme: themeReducer,
    // Add the generated reducer as a specific top-level slice
    [elmApi.reducerPath]: elmApi.reducer,
  },
  // Preloads the palletmodes initial state from initialPaletteMode
  preloadedState: {
    theme: { paletteMode: initialPaletteMode as PaletteMode },
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(elmApi.middleware),
})

// Subscribe updates the state of light or dark mode in store and changes the value in the local storage accordingly
store.subscribe(() => {
  const state = store.getState()
  localStorage.setItem("paletteMode", state.theme.paletteMode)
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
